<template>
    <div>
        <Wapheader />
         <img  src="../../assets/images/c4.png"/>
        <Wapfooter />
    </div>
</template>
<script>
export default {
    data() {
        return {
   
        }
    },
    mounted(){
    }
}
</script>
<style lang="less" scoped>

</style>